/* eslint-disable consistent-return */
const Sentry = () => import(/* webpackChunkName: 'sentry' */'./sentry');
const ExternalServices = () => import(/* webpackChunkName: 'external-services' */'./external-services');

(() => {
  if (typeof window === 'undefined') return;

  /**
   * Hack for apply confirmation token of netlify without pain
   */
  if (window.location.pathname === '/'
    && window.location.hash.includes('#confirmation_token=')) {
    window.location = `/admin${window.location.hash}`;
  }

  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-inner-declarations
    async function boot() {
      if (boot.singletonSemaphore) return;
      boot.singletonSemaphore = true;
      await Sentry();
      await ExternalServices();
    }

    const events = ['mousemove', 'scroll', 'keydown', 'click', 'touchstart'];

    // eslint-disable-next-line no-inner-declarations
    function onUserInteracted() {
      events.forEach((event) => document.removeEventListener(event, boot));
      if (window.localStorage) window.localStorage.setItem('should-start-immediate', '1');
      boot();
    }

    if (window.localStorage && window.localStorage.getItem('should-start-immediate')) {
      return boot();
    }

    events.forEach((event) => document.addEventListener(event, onUserInteracted));

    setTimeout(boot, 3000);
  }
})();
