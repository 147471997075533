/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import satoshiBlack from '../src/assets/satoshi-cufonfonts-webfont/Satoshi-Black.woff2';
import satoshiMedium from '../src/assets/satoshi-cufonfonts-webfont/Satoshi-Medium.woff2';
import satoshiRegular from '../src/assets/satoshi-cufonfonts-webfont/Satoshi-Regular.woff2';
import '../src/boot.client';
import '../src/styles/global.scss';

export default function App({ Component, pageProps }) {
  return (
    <ParallaxProvider>
      <Head>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <title>Mapletechtalent.com</title>
        {/* <meta name="viewport" content="width=device-width, user-scalable=no" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preload" href={satoshiRegular} as="font" type="font/woff2" />
        <link rel="preload" href={satoshiMedium} as="font" type="font/woff2" />
        <link rel="preload" href={satoshiBlack} as="font" type="font/woff2" />
      </Head>
      <Component {...pageProps} />
    </ParallaxProvider>
  );
}
